import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from 'ant-design-vue';
import "ant-design-vue/dist/antd.less";
import "@/common/css/font.scss";
import '@/assets/iam-iconfont/iconfont.css'

import PlusUi from 'plus-ui';
import 'plus-ui/lib/style.css';

// import axios from "axios";
//异步注入百度地图的AK  然后再加载组件   暂时先不用
// axios.get("/api/base/v1/areaselect/province/find").then(() => {
//   var script = document.createElement("script");
//   script.setAttribute(
//     "src",
//     "https://api.map.baidu.com/getscript?v=3.0&ak=woXDNV17Y1C7FNg9nnqaPObEymOB9adG&services=&t=20220113143055"
//   );
//   document.getElementsByTagName("head")[0].appendChild(script);
//   createApp(App).use(store).use(router).mount("#app");
// });
createApp(App).use(store).use(router).use(Antd).use(PlusUi).mount("#app");
