
import { defineComponent } from "vue";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { ConfigProvider } from "ant-design-vue";
import "@/common/css/font.scss";
export default defineComponent({
  data() {
    return {
      locale: zhCN,
    };
  },
  components: {
    ConfigProvider,
  },
  created() {
    let re = /^.+(Num|Size)$/;
    var sessionKeys = Object.keys(sessionStorage);
    for (var i = 0; i < sessionKeys.length; i++) {
      if (re.test(sessionKeys[i])) {
        sessionStorage.removeItem(sessionKeys[i]);
      }
    }
  },
});
