
import { defineComponent } from "vue";
import { Menu } from "ant-design-vue";
import { LinkOutlined } from "@ant-design/icons-vue";
const { Item, SubMenu } = Menu;
export default defineComponent({
  name: "Sub-menu2",
  components: {
    Item,
    SubMenu,
    LinkOutlined,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleClick(item) {
      if (item.isLink) {
        switch (item.openType) {
          case 1:
            window.open(item.path, "_self");
            break;
          case 2:
            window.open(item.path);
            break;
          default:
            window.open(item.path, "_self");
            break;
        }
      } else {
        switch (item.openType) {
          case 1:
            (this as any).$router.push(item.path);
            break;
          case 2:
            window.open(item.path);
            break;
          default:
            (this as any).$router.push(item.path);
            break;
        }
      }
      // this.selectedKeys = ["/masterData/singleProduct/parameter"];
    },
  },
});
