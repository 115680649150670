import { createStore } from "vuex";
import { categoryList, channelLists } from "@/api/terminalList.js";
import currencyModule from './modules/currency'//引入模块
export default createStore({
  modules: {
    currency: currencyModule, //添加模块  currency为模块命名空间自动注册路径
  },
  state: {
    haveSeenTerminal: false,//是否已经见过完成导入状态
    haveSeenSingle: false,//是否已经见过完成导入状态
    newMessages: [],//用户的未读信息
    userInfo: {}, //用户信息
    sideBarApp: [], //侧边栏涉及的其他app名字和路径
    isLogin: false, //是否登录过如果没有就先跑登录等几个接口
    breadcrumb: ["主数据中心", "终端管理", "终端列表"],
    lng: 116.404,
    lat: 39.915,
    //品类管理相关
    categoryEdit: false,//全局只能有一个编辑或者新增当此状态存在时禁用新增分类 更改排序 编辑 删除
    channelEdit: false,
    gradeCode1: [],
    grade1: "",
    gradeCode2: [],
    grade2: "",
    gradeCode3: [],
    grade3: "",
    gradeCode4: [],
    grade4: "",
    channelCode1: [],
    channel1: "",
    channelCode2: [],
    channel2: "",
    channelCode3: [],
    channel3: "",
    loadingStatus: false, //全局loading状态
    categoryPromiseList: [],//排序的异步请求数组 当长度为0时表示请求全部发送结束
    categoryPromiseList2: [],//排序的异步请求数组 当长度为0时表示请求全部发送结束
    categoryPromiseList3: [],//排序的异步请求数组 当长度为0时表示请求全部发送结束
    categoryPromiseList4: [],//排序的异步请求数组 当长度为0时表示请求全部发送结束
    categoryPromiseList5: [],//排序的异步请求数组 当长度为0时表示请求全部发送结束
    channelPromiseList: [],//排序的异步请求数组 当长度为0时表示请求全部发送结束
    channelPromiseList2: [],//排序的异步请求数组 当长度为0时表示请求全部发送结束
    channelPromiseList3: [],//排序的异步请求数组 当长度为0时表示请求全部发送结束
    channelPromiseList4: [],//排序的异步请求数组 当长度为0时表示请求全部发送结束
    apiMenu: {},
  },
  mutations: {
    haveSeenTerminal(state, haveSeenTerminal) {
      state.haveSeenTerminal = haveSeenTerminal;
    },
    haveSeenSingle(state, haveSeenSingle) {
      state.haveSeenSingle = haveSeenSingle;
    },
    categoryPromiseList_push(state: any, promise) {
      state.categoryPromiseList.push(promise)
    },
    categoryPromiseList_shift(state: any) {
      state.categoryPromiseList.shift()
    },
    categoryPromiseList2_push(state: any, promise) {
      state.categoryPromiseList2.push(promise)
    },
    categoryPromiseList2_shift(state: any) {
      state.categoryPromiseList2.shift()
    },
    categoryPromiseList3_push(state: any, promise) {
      state.categoryPromiseList3.push(promise)
    },
    categoryPromiseList3_shift(state: any) {
      state.categoryPromiseList3.shift()
    },
    categoryPromiseList4_push(state: any, promise) {
      state.categoryPromiseList4.push(promise)
    },
    categoryPromiseList4_shift(state: any) {
      state.categoryPromiseList4.shift()
    },
    categoryPromiseList5_push(state: any, promise) {
      state.categoryPromiseList5.push(promise)
    },
    categoryPromiseList5_shift(state: any) {
      state.categoryPromiseList5.shift()
    },
    changeCategoryEdit(state, categoryEdit) {
      state.categoryEdit = categoryEdit;
    },
    changeChannelEdit(state, channelEdit) {
      state.channelEdit = channelEdit;
    },
    changeNewMessages(state, newMessages) {
      state.newMessages = newMessages;
    },
    changeUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    changeSideBarApp(state, sideBarApp) {
      state.sideBarApp = sideBarApp;
    },
    changeisLogin(state, isLogin) {
      state.isLogin = isLogin;
    },
    changeloadingStatus(state, loadingStatus) {
      state.loadingStatus = loadingStatus;
    },
    changeBreadcrumb(state, breadcrumb) {
      state.breadcrumb = breadcrumb;
    },
    changelng(state, lng) {
      state.lng = lng;
    },
    changelat(state, lat) {
      state.lat = lat;
    },
    changeGradeCode1(state: any, gradeCode1: string) {
      state.grade1 = gradeCode1;
      console.log('changeGradeCode1', gradeCode1);
      categoryList(gradeCode1).then((res: any) => {
        let items = res.data.data;
        // let Pall: any = [];
        items.forEach((element, i) => {
          element.beforeSort = element.sort;
          element.people = 2;
          element.subordinate = element.isHave;
        });
        state.gradeCode1 = items;
        // state.categoryEdit = false
      });
    },
    changeGradeCode2(state: any, gradeCode2: string) {
      state.grade2 = gradeCode2;
      console.log('changeGradeCode2', gradeCode2);
      categoryList(gradeCode2).then((res: any) => {
        let items = res.data.data;
        // let Pall: any = [];
        items.forEach((element, i) => {
          element.beforeSort = element.sort;
          element.people = 3;
          element.subordinate = element.isHave;
        });
        state.gradeCode2 = items;
        // state.categoryEdit = false
      });
    },
    changeGradeCode3(state: any, gradeCode3) {
      state.grade3 = gradeCode3;
      console.log('changeGradeCode3', gradeCode3);
      categoryList(gradeCode3).then((res: any) => {
        let items = res.data.data;
        // let Pall: any = [];
        items.forEach((element, i) => {
          element.beforeSort = element.sort;
          element.people = 4;
          element.subordinate = element.isHave;
        });
        state.gradeCode3 = items;
        // state.categoryEdit = false
      });
    },
    changeGradeCode4(state: any, gradeCode4) {
      state.grade4 = gradeCode4;
      console.log('changeGradeCode4', gradeCode4);
      categoryList(gradeCode4).then((res: any) => {
        let items = res.data.data;
        // let Pall: any = [];
        items.forEach((element, i) => {
          element.beforeSort = element.sort;
          element.people = 5;
          element.subordinate = element.isHave;
        });
        state.gradeCode4 = items;
        // state.categoryEdit = false
      });
    },
    channelPromiseList_push(state: any, promise) {
      state.channelPromiseList.push(promise)
    },
    channelPromiseList_shift(state: any) {
      state.channelPromiseList.shift()
    },
    channelPromiseList2_push(state: any, promise) {
      state.channelPromiseList2.push(promise)
    },
    channelPromiseList2_shift(state: any) {
      state.channelPromiseList2.shift()
    },
    channelPromiseList3_push(state: any, promise) {
      state.channelPromiseList3.push(promise)
    },
    channelPromiseList3_shift(state: any) {
      state.channelPromiseList3.shift()
    },
    channelPromiseList4_push(state: any, promise) {
      state.channelPromiseList4.push(promise)
    },
    channelPromiseList4_shift(state: any) {
      state.channelPromiseList4.shift()
    },
    changeChannelCode1(state: any, channelCode1) {
      state.channel1 = channelCode1;
      channelLists({ parCode: channelCode1 }).then((res: any) => {
        let items = res.data.data;
        // let Pall: any = [];
        items.forEach((element, i) => {
          element.beforeSort = element.sort;
          element.people = 2;
          element.subordinate = element.isHave;
        });
        state.channelCode1 = items;
        // state.categoryEdit = false
      });
    },
    changeChannelCode2(state: any, channelCode2) {
      state.channel2 = channelCode2;
      channelLists({ parCode: channelCode2 }).then((res: any) => {
        let items = res.data.data;
        // let Pall: any = [];
        items.forEach((element, i) => {
          element.beforeSort = element.sort;
          element.people = 3;
          element.subordinate = element.isHave;
        });
        state.channelCode2 = items;
        // state.categoryEdit = false
      });
    },
    changeChannelCode3(state: any, channelCode3) {
      state.channel3 = channelCode3;
      channelLists({ parCode: channelCode3 }).then((res: any) => {
        let items = res.data.data;
        // let Pall: any = [];
        items.forEach((element, i) => {
          element.beforeSort = element.sort;
          element.people = 4;
          element.subordinate = element.isHave;
        });
        state.channelCode3 = items;
        // state.categoryEdit = false
      });
    },
    apiMenu(state: any, val) {
      state.apiMenu = val
    }
  },
  actions: {},
});
