import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkOutlined = _resolveComponent("LinkOutlined")!
  const _component_Item = _resolveComponent("Item")!
  const _component_SubMenu2 = _resolveComponent("SubMenu2", true)!
  const _component_Sub_menu = _resolveComponent("Sub-menu")!

  return (_openBlock(), _createBlock(_component_Sub_menu, {
    key: _ctx.item.idx || _ctx.item.path,
    class: _normalizeClass(["SubmenuOne", !_ctx.item.children ? 'show' : '']),
    title: _ctx.item.meta && _ctx.item.meta.title ? _ctx.item.meta.title : _ctx.item.name,
    style: {"font-weight":"600"}
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (data) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: data.idx || data.path
        }, [
          (!data.children)
            ? (_openBlock(), _createBlock(_component_Item, {
                key: data.idx || data.path,
                onClick: ($event: any) => (_ctx.handleClick(data)),
                disabled: data.disable,
                class: _normalizeClass([data.meta.hidden === true ? 'layout_sideBar_hiddenBox' : '', "dataItem"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(data.meta.title || data.name), 1),
                  (data.isLink)
                    ? (_openBlock(), _createBlock(_component_LinkOutlined, { key: 0 }))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["onClick", "disabled", "class"]))
            : (_openBlock(), _createBlock(_component_SubMenu2, {
                key: 1,
                item: data
              }, null, 8, ["item"]))
        ], 64))
      }), 128))
    ]),
    _: 1
  }, 8, ["class", "title"]))
}