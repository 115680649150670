
import { defineComponent, ref } from "vue";
import Header from "@/components/layout/Header.vue";
import SideBar from "@/components/layout/SideBar.vue";
import { Layout } from "ant-design-vue";
import { getApp, userInfo, getMsgByUrl } from "@/api/sideBarApi";

export default defineComponent({
  components: {
    Header,
    SideBar,
    Layout,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    handleVisiable: function (e) {
      let document = e.target;
      if (document.visibilityState == "hidden") {
        // console.log("离开当前tab标签", this);
      } else {
        // console.log("回到当前tab标签", this);
        userInfo().then((res: any) => {
          let resName = res.data.data.tenant;
          let name = document.querySelector(".userNameforreload").innerHTML;
          if (resName != name) {
            location.reload();
          }
        });
      }
    },
    //获取cookie
    getCookie(key) {
      var re = new RegExp(key + "=([^;]+)");
      return (document as any).cookie.match(re)[1];
    },
  },
  created() {
    getApp()
      .then((res: any) => {
        // res.data.data=[];
        (this as any).$store.commit("changeSideBarApp", res.data.data || []);
        this.show = true;
      })
      .catch(() => {
        this.show = true;
      });
    userInfo().then((res: any) => {
      (this as any).$store.commit("changeUserInfo", res.data.data);
      document.title = res.data.data.tenant;
      // (this as any).$refs.SideBar.getAppList();
      // 添加监听事件
      document.addEventListener("visibilitychange", this.handleVisiable);
      //获取用户的未读消息
      getMsgByUrl(res.data.data.msg_uri, {
        Authorization: "Bearer " + this.getCookie("kat"),
      }).then((res_message: any) => {
        // console.log(res_message.data.data);
        (this as any).$store.commit("changeNewMessages", res_message.data.data);
      });
    });
  },
});
