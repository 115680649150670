
import { defineComponent } from "vue";
import { Layout, Button, Menu } from "ant-design-vue";
import Content from "./Content.vue";
import SubMenu2 from "./SubMenu2.vue";
import { getMenu } from "@/api/sideBarApi";
import { LinkOutlined } from "@ant-design/icons-vue";

const { Item, SubMenu } = Menu;
const { Sider } = Layout;

export default defineComponent({
  components: {
    Layout,
    Button,
    Sider,
    Content,
    Item,
    Menu,
    SubMenu,
    LinkOutlined,
    SubMenu2,
  },
  data() {
    return {
      //初始选中和展开数组
      openKeys: [],
      list: [],
      menuHeight: "300px",
      selectedKeys: [this.$route.path],
      showSider: true,
      authcList: [],
      breadcrumbType: "",
    };
  },
  methods: {
    handleClick(item) {
      if (item.isLink) {
        switch (item.openType) {
          case 1:
            window.open(item.path, "_self");
            break;
          case 2:
            window.open(item.path);
            break;
          default:
            window.open(item.path, "_self");
            break;
        }
      } else {
        switch (item.openType) {
          case 1:
            (this as any).$router.push(item.path);
            break;
          case 2:
            window.open(item.path);
            break;
          default:
            (this as any).$router.push(item.path);
            break;
        }
      }
      // this.selectedKeys = ["/masterData/singleProduct/parameter"];
    },
    //将后端获取的侧边栏 以及后添加的最外级菜单 拼接到我们系统上
    handleList(list) {
      let sidebar;
      (this as any).$router.getRoutes().forEach((ele) => {
        if (ele.path === "/") {
          return (sidebar = ele);
        }
      });
      (this as any).list = [...list, ...this.list, ...sidebar.children];
    },
    //获取后端传过来的侧边栏
    getAppList() {
      let list: any = [];
      (this as any).$store.state.sideBarApp.forEach((element) => {
        let item: any = {};
        item.name = element.meta.title;
        item.path = element.path;
        item.isLink = element.meta.isExternalLinks || true; //不传false就默认是外链
        item.meta = { hidden: false, title: element.meta.title };
        list.push(item);
      });
      this.handleList(list);
    },
    //从路由获取打开的侧边栏
    getOpenKeys() {
      let array = this.$route.matched.map((item) => {
        return item.path;
      });
      return array;
    },
    //检测是否存在新菜单 存在使用新菜单逻辑不存在使用旧逻辑
    checkNewMenu() {
      return new Promise(async (resolve, reject) => {
        let res: any = await getMenu().catch(() => {
          resolve(false);
        });
        if (
          res &&
          res.data &&
          res.data.success &&
          res.data.data.menu &&
          res.data.data.menu.length
        ) {
          const data = res.data.data;
          this.openKeys = data.info.skeys;
          this.selectedKeys = [data.info.skeys[data.info.skeys.length - 1]];
          this.breadcrumbType = "apiMenu";
          (this as any).$store.commit("apiMenu", data);
          resolve(data.menu);
          return;
        } else {
          resolve(false);
        }
      });
    },
  },
  async created() {
    this.menuHeight = document.body.clientHeight - 120 + "px";
    //检测是否存在新菜单 存在使用新菜单逻辑不存在使用旧逻辑
    let resList = await this.checkNewMenu();
    if (resList) {
      (this as any).list = resList;
      return;
    }
    //不存在新菜单 使用旧的getApp方法
    let sidebar;
    (this as any).$router.getRoutes().forEach((ele) => {
      if (ele.path === "/masterData") {
        return (sidebar = ele);
      }
    });
    (this as any).list = [sidebar];
    (this as any).openKeys = this.getOpenKeys();
    this.breadcrumbType = "localMenu";
    this.getAppList();
  },
});
