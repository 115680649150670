const currencyModule = {
    namespaced: true,//开启命名空间 
    state: () => ({
        // test: 1,  //模块中的状态   页面调用(this as any).$store.state.currency.test
    }),
    mutations: {
        //修改模块中的状态   页面调用(this as any).$store.commit('currency/setTest',3)
        // setTest(state, test) {
        //     state.test = test;
        // }
    },
    actions: {},
    getters: {}
}
export default currencyModule